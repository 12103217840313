<template>
  <div>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <v-card-title class="filter-card mt-2">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              :hint="isSearchAdded ? searchHint : ''"
              append-icon="mdi-magnify"
              outlined
              persistent-hint
              dense
              @keyup.enter="handleSearch"
              @keydown.esc="getAllRecordsFromServer({itemsPerPage: 10, pageStart: 0});"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            lg="2"
            md="5"
            sm="11"
            class="text-right"
          >
            <!-- date dialog -->
            <v-dialog
              ref="dialog"
              v-model="dateDialog"
              :return-value.sync="selectedDate"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-filter
                  </v-icon>
                  {{ selectedDate }}
                </v-chip>
              </template>
              <v-date-picker
                v-model="selectedDate"
                color="#37474F"
                scrollable
                :max="maxStartDate"
                @input="handleDateInput(selectedDate)"
              >
                <v-btn
                  text
                  color="primary"
                  @click="dateDialog = false"
                >
                  Cancel
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            md="2"
            sm="12"
            lg="3"
          >
            <v-autocomplete
              v-model="selectedCompany"
              :items="companiesList"
              item-text="name"
              label="Company*"
              single-line
              append-icon="mdi-office-building-plus-outline"
              outlined
              dense
              return-object
              @input="handleCompanyFilter"
            />
          </v-col>
          <v-col lg="1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  dark
                  outlined
                  color="#37474F"
                  small
                  v-on="on"
                  @click="handleFilterOff"
                >
                  <v-icon
                    small
                    dark
                  >
                    mdi-filter-off-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>All records</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="attendance"
        disable-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 30],
        }"
        :loading="updateProgress"
        :server-items-length="totalRecords"
        class="elevation-1 table-projects"
        mobile-breakpoint="100"
        show-current-page
        light
        :page.sync="page"
        @pagination="handlePagination"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <span class="info-container">
                {{ item.app_user.first_name }} {{ item.app_user.last_name }}
              </span>
            </td>
            <td>
              <span v-if="item.app_user.company !== null">{{ item.app_user.company.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span
                v-if="item.app_user.app_user_role !== null"
                class="type-style"
              > {{ item.app_user.app_user_role === 'site-manager'?'site manager': item.app_user.app_user_role }} </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>{{ item.checkin_record.project.name }}</td>
            <td class="type-style">
              <v-chip
                outlined
                class="type"
                color="#37474F"
                dark
              >
                {{ item.checkin_record.type | staffToSiteManager }}
              </v-chip>
            </td>
            <td>{{ item.checkin_record.checkin_time | convertToLocal }}</td>
            <td>
              <font
                v-if="item.checkin_record.checkout_time === null"
                class="My-checkout-time"
              >
                Onsite*
              </font>
              <font v-else>
                {{ item.checkin_record.checkout_time | convertToLocal }}
                <v-tooltip
                  v-if="item.manual_checkout === true"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      color="#C62828"
                      v-on="on"
                    >
                      mdi-account-arrow-right
                    </v-icon>
                  </template>
                  <span>Checkout Was Done Manually</span>
                </v-tooltip>
              </font>
            </td>
            <td>{{ item.checkin_record.total_hours }}Hrs</td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click.stop
                @click="getAttendanceDetails(item.checkin_record.id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner :loading="loading" />
  </div>
</template>

<script>
import moment from 'moment';
import spinner from 'src/views/dashboard/component/SpinnerCentre';

export default {
    name: 'OnsiteAttendance',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
        arrangePretty (val) {
          return val.join(', ');
        },
        convertToLocal (stringDatetime) {
          if (!stringDatetime == null) {
            return 'Active';
          } else {
            return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
          }
        },
        staffToSiteManager (val) {
        if (val === 'staff') {
          return 'Site Manager';
        }
        return val;
      },
      },
    data () {
        return {
            search: null,
            showForm: false,
            loading: false,
            selectedDate: null,
            dateDialog: false,
            headers: [
                { text: 'Name', align: 'start', value: 'first_name' },
                { text: 'Company', value: 'company' },
                { text: 'Role', value: 'role' },
                { text: 'Project', value: 'Project' },
                { text: 'Checkin Type', value: 'type' },
                { text: 'Check-In At', value: 'checkin_time' },
                { text: 'Check-Out At', value: 'checkout_time' },
                { text: 'Total hours', value: 'total_hours' },
                { text: 'More', value: 'created_at' },
            ],
            selectedCompany: null,
            updateProgress: false,
            maxStartDate: moment().format('YYYY-MM-DD'),
            previousRoute: null,
            dateChanged: false,
            page: 1,
        };
    },
    computed: {
        isSearchAdded () {
          if (this.search === null || this.search === '') {
            return false;
          }
          return true;
        },
        searchHint () {
          return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
        },
        companiesList () {
          return this.$store.getters['companies/getCompaniesList'];
        },
        paginatedAttendanceStructure () {
            return this.$store.getters['attendance/getOnsiteAttendance'];
        },
        attendance () {
          const records = this.paginatedAttendanceStructure;
          return records.data;
        },
        totalRecords () {
          if (Object.keys(this.paginatedAttendanceStructure).length > 0) {
            return this.paginatedAttendanceStructure.total;
          }
          return 0;
        },
        savedDetails () {
           return this.$store.getters['attendance/getSavedDetails'];
        },
    },
    watch: {
        selectedDate () {
          this.saveDetails();
          this.pageStart = 0;
        },
        selectedCompany () {
          this.saveDetails();
          this.pageStart = 0;
        },
    },
    async mounted () {
        this.checkIfData();
      },
    methods: {
      handleDateInput (selectedDate) {
        this.$refs.dialog.save(selectedDate);
        this.dateChanged = true;
        this.getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
      },
      async checkIfData () {
        const preRouteName = this.$router.history.current.meta.previousRoute.name;
        if (Object.keys(this.paginatedAttendanceStructure).length !== 0 && preRouteName === 'Record Details') {
          this.selectedCompany = this.savedDetails.company;
          this.selectedDate = moment(this.savedDetails.date).format('YYYY-MM-DD');
        } else {
        this.selectedDate = moment().format('YYYY-MM-DD');
        await this.getCompaniesList();
        }
      },
      handlePagination (e) {
            if (this.dateChanged) {
                this.pageStart = 0;
                this.getRecordsFromServer({ itemsPerPage: 10, pageStart: this.pageStart });
                this.dateChanged = false;
                this.page = 1;
            } else {
              this.pageStart = e.pageStart;
              this.getRecordsFromServer({ itemsPerPage: 10, pageStart: this.pageStart });
            }
        },
        handleSearch () {
                this.pageStart = 0;
                this.page = 1;
                this.getRecordsFromServer({ itemsPerPage: 10, pageStart: this.pageStart });
        },
        handleCompanyFilter () {
                this.pageStart = 0;
                this.page = 1;
                this.getRecordsFromServer({ itemsPerPage: 10, pageStart: this.pageStart });
        },
        handleFilterOff () {
          this.pageStart = 0;
          this.page = 1;
          this.getNonFilteredRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
        },
        async getAllRecordsFromServer ({ itemsPerPage, pageStart }) {
          this.updateProgress = true;
          this.search = null;
          this.selectedCompany = null;
            await this.$store.dispatch('attendance/fetchOnsiteAttendance', {
             params: {
                      limit: itemsPerPage,
                      startPage: pageStart,
                      date: this.selectedDate,
             },
          }).catch(() => {
            this.updateProgress = false;
          });
          this.updateProgress = false;
        },
        async getNonFilteredRecordsFromServer ({ itemsPerPage, pageStart }) {
          this.updateProgress = true;
          this.search = null;
          this.selectedCompany = null;
            await this.$store.dispatch('attendance/fetchOnsiteAttendance', {
             params: {
                      limit: itemsPerPage,
                      startPage: pageStart,
                      date: this.selectedDate = moment().format('YYYY-MM-DD'),
             },
          }).catch(() => {
            this.updateProgress = false;
          });
          this.updateProgress = false;
        },
        async getRecordsFromServer ({ itemsPerPage, pageStart }) {
          this.updateProgress = true;
          await this.$store.dispatch('attendance/fetchOnsiteAttendance', {
              params: {
                name: this.search === '' ? null : this.search,
                companyId: this.selectedCompany === null ? null : this.selectedCompany.id,
                limit: itemsPerPage,
                startPage: pageStart,
                date: this.selectedDate ? this.selectedDate : moment().format('YYYY-MM-DD'),
              },
          }).catch(() => {
            this.updateProgress = false;
            this.dateDialog = false;
          });
          this.updateProgress = false;
        },
        async getCompaniesList () {
          this.loading = true;
          await this.$store.dispatch('companies/fetchCompaniesList').catch(() => {
            this.loading = false;
          });
          this.loading = false;
        },
        getAttendanceDetails (recordId) {
         this.$router.push(`records/${recordId}`);
        },
        async saveDetails () {
          const data = {
            company: this.selectedCompany,
            date: this.selectedDate,
          };
          await this.$store.dispatch('attendance/savePreDetails', data);
        },
    },
};
</script>

<style scoped>
  .v-card {
    margin-top: 0px!important;
  }
  .v-data-table::v-deep th {
    font-size: 12px !important;
    color: #37474F !important;
    font-weight: bold !important;
  }
  .v-data-table::v-deep td {
    font-size: 13px !important;
    color: #37474F !important;
  }
  .v-data-table { background-color: #ECEFF1; }
  .icon-style {
    color: #37474F;
    font-size: 45px;
  }
  .info-container {
    cursor: pointer;
    color: #37474F;
  }
  .all-users-btn {
    margin-bottom: 25px;
  }
  .header {
  display: inline-block;
  width: 100%;
}
.chip-container{
  display: flex;
  justify-content: space-between;
}
.type-style{
  text-transform: capitalize;
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
</style>
